import React, { CSSProperties } from 'react';

import { Typography } from '@mui/material';

import {
  BLACK2,
  COOL_GRAY700,
  GRAY600,
  STATUS_ERROR,
  TEXT_DISABLED,
  WHITE,
} from '../../../theme';

const FONT_SHARP = 'sharp';
const FONT_READER = 'reader';

export enum TextKind {
  TitleXl = 'TitleXl',
  TitleL = 'TitleL',
  TitleM = 'TitleM',
  TitleS = 'TitleS',
  TitleXs = 'TitleXs',
  TitleXXs = 'TitleXXs',
  SubtitleXs = 'SubtitleXs',
  BodyL = 'BodyL',
  BodyM = 'BodyM',
  BodyS = 'BodyS',
  BodyXs = 'BodyXs',
  BodySM = 'BodySM',
  LabelL = 'LabelL',
  LabelM = 'LabelM',
  LabelS = 'LabelS',
  ButtonL = 'ButtonL',
  ButtonM = 'ButtonM',
}

export enum TextColor {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Contrast = 'Contrast',
  DarkerGray = 'DarkerGray',
  Error = 'Error',
  Disabled = 'Disabled',
}

interface Props {
  kind?: TextKind;
  color?: TextColor;
  component?: React.ElementType;
  /** Try to avoid using this parameter. Ideally all typography variations are covered by the `kind` prop */
  style?: React.CSSProperties;
  children?: React.ReactNode;
  /** Currently only supported by BodyM, BodyS */
  bold?: boolean;
  testId?: string;
  htmlFor?: string;
  title?: string;
  textTransform?: React.CSSProperties['textTransform'];
}

export const textStyleFrom = (
  kind: TextKind,
  color: TextColor,
  opts?: {
    bold?: boolean;
    textTransform?: React.CSSProperties['textTransform'];
  },
) => ({
  lineHeight: 1,
  ...{
    TitleXl: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 40,
      textTransform: opts?.textTransform,
    },
    TitleL: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 30,
      textTransform: opts?.textTransform,
    },
    TitleM: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 24,
      textTransform: opts?.textTransform,
    },
    TitleS: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 20,
      textTransform: opts?.textTransform,
    },
    TitleXs: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 16,
      textTransform: opts?.textTransform,
    },
    TitleXXs: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 14,
      textTransform: opts?.textTransform,
    },
    SubtitleXs: {
      fontFamily: FONT_READER,
      fontWeight: 400,
      fontSize: 12,
      textTransform: opts?.textTransform,
    },
    BodyL: {
      fontFamily: FONT_READER,
      fontWeight: 400,
      fontSize: 16,
      textTransform: opts?.textTransform,
    },
    BodyM: {
      fontFamily: FONT_READER,
      fontWeight: opts?.bold ? 700 : 400,
      fontSize: 14,
      textTransform: opts?.textTransform,
    },
    BodyS: {
      fontFamily: FONT_READER,
      fontWeight: opts?.bold ? 600 : 400,
      fontSize: 12,
      textTransform: opts?.textTransform,
    },
    BodyXs: {
      fontFamily: FONT_READER,
      fontWeight: opts?.bold ? 600 : 400,
      fontSize: 10,
      textTransform: opts?.textTransform,
    },
    BodySM: {
      fontFamily: FONT_READER,
      fontWeight: 600,
      fontSize: 12,
      textTransform:
        opts?.textTransform ?? ('uppercase' as CSSProperties['textTransform']),
    },
    LabelL: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 14,
      textTransform: opts?.textTransform,
    },
    LabelM: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 12,
      textTransform:
        opts?.textTransform ?? ('uppercase' as CSSProperties['textTransform']),
      display: 'inline-block',
    },
    LabelS: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 10,
      textTransform:
        opts?.textTransform ?? ('uppercase' as CSSProperties['textTransform']),
    },
    ButtonL: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 14,
      textTransform: opts?.textTransform,
    },
    ButtonM: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 12,
      textTransform: opts?.textTransform,
    },
  }[kind],
  ...{
    color: {
      Primary: BLACK2,
      Secondary: COOL_GRAY700,
      Disabled: TEXT_DISABLED,
      DarkerGray: GRAY600,
      Contrast: WHITE,
      Error: STATUS_ERROR,
    }[color],
  },
});

const Text: React.FC<Props> = ({
  kind = TextKind.BodyS,
  children,
  component = 'p',
  color = TextColor.Primary,
  style = {},
  bold = false,
  testId,
  htmlFor,
  title,
  textTransform,
}) => (
  <Typography
    style={{
      ...textStyleFrom(kind, color, { bold, textTransform }),
      ...style,
    }}
    data-kind={kind}
    data-color={color}
    data-bold={bold}
    component={component}
    data-testid={testId}
    htmlFor={htmlFor}
    title={title}
  >
    {children}
  </Typography>
);

export default Text;
