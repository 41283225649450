import React, { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useAuth } from '@teamexos/fit-shared';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CoverLogin from '../../assets/images/login-cover.webp';
import useForm from '../../hooks/useForm';
import useLocalStorage from '../../hooks/useLocalStorage';
import useTracking from '../../hooks/useTracking';
import {
  EventCategories,
  EventNames,
  ScreenNames,
} from '../../utils/trackingEvents';
import LoginLayout from '../LoginLayout';
import Button, { ButtonSize, ButtonVariant } from '../reusable/Button';
import { LinkButton } from '../reusable/Button/LinkButton';
import Errors from '../reusable/ErrorMessage';
import Text, { TextColor, TextKind } from '../reusable/Text';

import { LoginField } from './LoginFields';
import {
  CoverImage,
  LoginForm,
  SignInWrapper,
  TextContainer,
} from './StyledSignIn';
import { SignInFlow } from './types';
import { getButtonText, getMixpanelEventsBasedOnFlow } from './utils';

const Title: React.FC<{ loggedInBefore?: boolean }> = ({ loggedInBefore }) => (
  <Text kind={TextKind.TitleL} component="h1" testId="login-title">
    {loggedInBefore ? 'Welcome Back!' : 'Welcome!'}
  </Text>
);

const Subtitle: React.FC<{ flow: SignInFlow }> = ({ flow }) => {
  switch (flow) {
    case SignInFlow.Email:
      return (
        <Text kind={TextKind.BodyL} color={TextColor.DarkerGray}>
          Enter your email address to continue.
        </Text>
      );
    case SignInFlow.Password:
      return (
        <Text kind={TextKind.BodyL} color={TextColor.DarkerGray}>
          Log in by entering the password for your account.
        </Text>
      );
    default:
      return null;
  }
};

const SignIn: React.FC = () => {
  const methods = useForm({ mode: 'onChange', criteriaMode: 'all' });
  const { authorize, authorizeWithPassword, isLoggedIn, userCheck } = useAuth();
  const [flow, setFlow] = React.useState<SignInFlow>(SignInFlow.Email);
  const [currentEmail, setCurrentEmail] = React.useState<string | undefined>();

  const [loggedInBefore, setLoggedInBefore] = useLocalStorage<boolean>(
    `login.logged_before`,
    false,
  );

  const {
    getValues,
    resetField,
    handleSubmit,
    register,
    formState: { isSubmitting },
    setServerError,
    watch,
  } = methods;

  const [isSSO, setIsSSO] = React.useState(false);
  const isLoading = isSubmitting || isSSO;

  watch();

  let buttonIsDisabled = true;

  if (flow === SignInFlow.Password) {
    buttonIsDisabled = isLoading || !getValues('password');
  } else {
    buttonIsDisabled = isLoading || !getValues('email');
  }
  const buttonIcon = isLoading ? <CircularProgress size={14} /> : null;

  const { track } = useTracking();
  const navigate = useNavigate();

  useEffect(() => {
    const event = getMixpanelEventsBasedOnFlow(flow);
    if (event.screen_name) {
      track(EventNames.ScreenView, {
        screen_name: event.screen_name,
      });
    }
  }, [flow, track]);

  const setPasswordFlow = () => {
    resetField('email');
    setFlow(SignInFlow.Password);
  };

  const signInWithPassword = async (email: string, password: string) => {
    try {
      await authorizeWithPassword({
        username: email,
        password,
      });
      setLoggedInBefore(true);
    } catch (e) {
      if (e.message.includes('Incorrect username or password.')) {
        setServerError('Incorrect email or password');
      } else {
        setServerError('Error signing in. Please contact Coach Hub support.');
      }
    }
  };

  const resetPasswordFlow = () => {
    // TODO in HUB 1380 reset password flow
    setServerError(
      'Password reset not ready. Please contact Coach Hub support.',
    );
  };

  const handleResetPassword = () => {
    track(EventNames.ForgotPassword, {
      event_category: EventCategories.SignIn,
      screen_name: ScreenNames.SignInPassword,
    });
    resetPasswordFlow();
  };

  const signInCognitoFlow = async (
    password: string,
    emailValue: string,
  ): Promise<undefined> => {
    const email = currentEmail ?? emailValue;
    const info = await userCheck(email);

    if (!info.useCognito) {
      setServerError(
        'Invalid auth configuration, please contact Coach Hub support',
      );
      return;
    }

    if (flow === SignInFlow.Password) {
      await signInWithPassword(email, password);
      return;
    }

    if (flow === SignInFlow.Email) {
      const isRegistered = info.registered;
      const isVerified = info?.hasCognitoUser && info.isEmailVerified;
      const isValidCognitoUser = isRegistered && isVerified;
      if (!isValidCognitoUser) {
        setServerError('Please contact Coach Hub support.');
        return;
      }

      if (info.resetRequired) {
        resetPasswordFlow();
        return;
      }

      if (info.isSSO) {
        try {
          setIsSSO(true);
          await authorize({
            email,
            connection: info.identity.connection,
            provider: info.identity.provider,
          });
          setLoggedInBefore(true);
        } catch (e) {
          setServerError('Unexpected error. Please try again later.');
          // eslint-disable-next-line no-console
          console.log('Error checking the user when logging in', e);
        }
      } else {
        setPasswordFlow();
      }
    }
  };

  const signIn = async () => {
    try {
      track(EventNames.ButtonClick, getMixpanelEventsBasedOnFlow(flow));
      setServerError('');

      const values = getValues();
      const emailValue = values.email?.trim() ?? currentEmail;
      if (emailValue) {
        setCurrentEmail(emailValue);
      }

      await signInCognitoFlow(values.password, emailValue);
    } catch {
      setServerError(
        'Unknown error occurred. Please contact Coach Hub support.',
      );
    }
  };

  if (isLoggedIn) {
    navigate('/app', { replace: true });

    return null;
  }

  return (
    <SignInWrapper>
      <LoginLayout>
        <LoginForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(signIn)();
          }}
        >
          <TextContainer data-testid="sign-in-header">
            <Title loggedInBefore={Boolean(loggedInBefore)} />
            <Subtitle flow={flow} />
          </TextContainer>
          <FormProvider {...methods}>
            <LoginField flow={flow} register={register} />

            {flow === SignInFlow.Password && (
              <div style={{ alignSelf: 'start' }}>
                <LinkButton
                  onClick={handleResetPassword}
                  testId="login-reset-password"
                >
                  <Text kind={TextKind.BodyM}>Forgot Password?</Text>
                </LinkButton>
              </div>
            )}

            <Button
              disabled={buttonIsDisabled}
              type="submit"
              testId="login-button"
              fullWidth
              size={ButtonSize.Large}
              variant={ButtonVariant.Dark}
              icon={buttonIcon}
            >
              {getButtonText(isLoading)}
            </Button>
            <Errors name="server" />
          </FormProvider>
        </LoginForm>
      </LoginLayout>
      <CoverImage src={CoverLogin} alt="EXOS Coach Hub cover" />
    </SignInWrapper>
  );
};

export default SignIn;
