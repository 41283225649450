import React from 'react';

import styled from 'styled-components';

import { LinkCSS, LinkDecoration } from '../Link';

import { ButtonIconPosition } from '.';

const StyledLinkButton = styled.button<{ $decoration: LinkDecoration }>`
  ${LinkCSS}
`;

interface LinkButtonProps extends React.PropsWithChildren {
  decoration?: LinkDecoration;
  testId?: string;
  onClick?: () => void;
  iconPosition?: ButtonIconPosition;
  icon?: React.ReactNode;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  decoration = LinkDecoration.All,
  testId,
  onClick,
  iconPosition = ButtonIconPosition.Left,
  icon,
}) => (
  <StyledLinkButton
    onClick={onClick}
    data-testid={testId}
    type="button"
    $decoration={decoration}
  >
    {icon && iconPosition === ButtonIconPosition.Left && icon}
    {children}
    {icon && iconPosition === ButtonIconPosition.Right && icon}
  </StyledLinkButton>
);
