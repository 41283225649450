import React from 'react';

import { EventLabels } from '../../../utils/trackingEvents';

import { NavigationItem, NavigationWrapper } from './Navigation';

export const DieticianNavigation = () => (
  <NavigationWrapper>
    <NavigationItem
      to="/app/sessions"
      testId="NavigationSessionsLink-Fit"
      eventLabel={EventLabels.SessionsTab}
    >
      Sessions
    </NavigationItem>
  </NavigationWrapper>
);
