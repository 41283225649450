import React from 'react';

import { AvatarProps, Avatar as MUIAvatar } from '@mui/material';

import type { UserDetailsFragment } from '../../../graphql/types';
import { BORDER_GRAY } from '../../../theme';
import Text, { TextColor, TextKind, textStyleFrom } from '../Text';

export type PhotoUserFragment = Pick<
  UserDetailsFragment,
  'avatar' | 'firstName' | 'lastName' | 'id'
>;
interface Props extends AvatarProps {
  size: number;
  user?: PhotoUserFragment | null;
}

const Avatar: React.FC<Props> = ({ size, user, sx, ...rest }) => {
  const { firstName, lastName, avatar: photo } = user ?? {};
  const preview = photo?.preview;
  const previewUrl = preview ? `url(${preview})` : undefined;

  const initials =
    firstName && lastName
      ? `${firstName?.slice(0, 1) || ''}${lastName?.slice(0, 1) || ''}`
      : 'N/A';

  return (
    <MUIAvatar
      {...rest}
      sx={{
        width: size,
        height: size,
        borderRadius: size / 2,
        backgroundColor: BORDER_GRAY,
        backgroundImage: previewUrl,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        borderWidth: 0,
        borderStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      data-testid="avatar"
      data-itemid={user?.id}
    >
      {photo ? (
        <img
          alt={`${firstName} ${lastName}'s avatar`}
          src={`${photo?.basePath}/${photo?.id}/${size}x${size}.jpg`}
        />
      ) : (
        <Text
          style={{
            ...textStyleFrom(TextKind.LabelM, TextColor.Secondary),
            fontSize: `${size / 2.5}px`,
          }}
        >
          {initials}
        </Text>
      )}
    </MUIAvatar>
  );
};

export default Avatar;
