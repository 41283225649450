import { LocalDate } from '@js-joda/core';
import canvasConfetti from 'canvas-confetti';

import { GREEN050, GREEN200, GREEN900 } from '../../../theme';

interface ConfettiConfig {
  name: string;
  colors?: string[];
  particleCount?: number;
  scalar: number;
  shapes: any[];
  startDate: string;
  endDate: string;
  annual?: boolean;
}

function emojisToShapes(emojis: string[], scalar: number): any[] {
  return emojis.map((text) =>
    canvasConfetti.shapeFromText({
      text,
      scalar,
    }),
  );
}

const defaultConfig: Omit<ConfettiConfig, 'startDate' | 'endDate'> = {
  name: 'default',
  colors: [GREEN050, GREEN200, GREEN900],
  shapes: ['square', 'square', 'circle'],
  scalar: 2,
};

const valentinesConfig: ConfettiConfig = {
  name: 'valentines',
  particleCount: 100,
  shapes: emojisToShapes(['💕', '💘', '💖'], 3),
  startDate: '2023-02-11',
  endDate: '2023-02-17',
  annual: true,
  scalar: 3,
};

const stPatricksConfig: ConfettiConfig = {
  name: 'st-patricks',
  shapes: emojisToShapes(['🍀'], 3),
  startDate: '2023-03-11',
  endDate: '2023-03-22',
  annual: true,
  scalar: 3,
};

const canadaDayConfig: ConfettiConfig = {
  name: 'canada-day',
  particleCount: 200,
  shapes: emojisToShapes(['🇨🇦'], 3),
  startDate: '2023-06-30',
  endDate: '2023-07-02',
  annual: true,
  scalar: 3,
};

const july4thConfig: ConfettiConfig = {
  name: 'july-4th',
  particleCount: 200,
  shapes: emojisToShapes(['🇺🇸'], 3),
  startDate: '2024-07-02',
  endDate: '2024-07-06',
  annual: true,
  scalar: 3,
};

const halloweenConfig: ConfettiConfig = {
  name: 'halloween',
  particleCount: 200,
  shapes: emojisToShapes(['🎃', '👻', '🕷️'], 3),
  startDate: '2023-10-28',
  endDate: '2023-11-01',
  annual: true,
  scalar: 3,
};

const thanksgivingConfig: ConfettiConfig = {
  name: 'thanksgiving',
  particleCount: 200,
  shapes: emojisToShapes(['🦃', '🍁'], 3),
  startDate: '2023-11-22',
  endDate: '2023-11-29',
  annual: true,
  scalar: 3,
};

const christmasConfig: ConfettiConfig = {
  name: 'christmas',
  particleCount: 200,
  shapes: emojisToShapes(['🎄', '🎅', '🎁'], 3),
  startDate: '2023-12-23',
  endDate: '2023-12-26',
  annual: true,
  scalar: 3,
};

const confettiConfigs: ConfettiConfig[] = [
  valentinesConfig,
  stPatricksConfig,
  canadaDayConfig,
  july4thConfig,
  halloweenConfig,
  thanksgivingConfig,
  christmasConfig,
];

export const getConfettiConfig = (now = LocalDate.now()) => {
  const eligibleConfig = confettiConfigs.find((config) => {
    if (config.startDate && config.endDate) {
      if (!config.annual) {
        return (
          now.isAfter(LocalDate.parse(config.startDate)) &&
          now.isBefore(LocalDate.parse(config.endDate))
        );
      } else {
        const currentYearStartDate = LocalDate.parse(config.startDate).withYear(
          now.year(),
        );
        const currentYearEndDate = LocalDate.parse(config.endDate).withYear(
          now.year(),
        );
        return (
          now.isAfter(currentYearStartDate) && now.isBefore(currentYearEndDate)
        );
      }
    }

    return false;
  });

  return eligibleConfig || defaultConfig;
};
