import React from 'react';

import { SplitFlags } from '../../../hooks/featureFlags';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { EventLabels } from '../../../utils/trackingEvents';

import { NavigationItem, NavigationWrapper } from './Navigation';

export const FitNavigation = () => {
  const { flags } = useFeatureFlags();

  const enabledReportExternalSessions =
    flags[SplitFlags.METHODOLOGY_BOT_ENABLED];
  return (
    <NavigationWrapper>
      <NavigationItem
        to="/app"
        testId="NavigationHomeLink-Fit"
        eventLabel={EventLabels.Home}
      >
        Home
      </NavigationItem>
      <NavigationItem
        to="/app/members"
        testId="NavigationMembersLink-Fit"
        eventLabel={EventLabels.Members}
      >
        Members
      </NavigationItem>
      <NavigationItem
        to="/app/sessions"
        testId="NavigationSessionsLink-Fit"
        eventLabel={EventLabels.SessionsTab}
      >
        Sessions
      </NavigationItem>
      {enabledReportExternalSessions && (
        <NavigationItem
          to="/app/methodology"
          testId="NavigationMethodologyLink-Fit"
          eventLabel={EventLabels.Methodology}
        >
          Coach O
        </NavigationItem>
      )}
    </NavigationWrapper>
  );
};
