import React from 'react';

import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

import Button, { ButtonVariant } from '../components/reusable/Button';
import { SnackbarVariant } from '../components/reusable/Snackbar';
import { useSnackbarContext } from '../components/reusable/Snackbar/SnackbarContextProvider';
import Text, { TextKind } from '../components/reusable/Text';
import { useUserActivityLazyQuery } from '../graphql/types';
import { EnvLevel, getEnvLevel } from '../utils/getEnvLevel';

const Container = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
`;

const Playground: React.FC = () => {
  const envLevel = getEnvLevel();
  const { setSnackbar } = useSnackbarContext();
  const [triggerApolloError] = useUserActivityLazyQuery({
    variables: {
      id: {} as string,
    },
  });

  const triggerApplicationError = () => {
    throw new Error('Application Error');
  };

  if (envLevel !== EnvLevel.DEV) {
    return null;
  }

  return (
    <Container>
      <Section>
        <Text kind={TextKind.TitleL}>Snackbars</Text>
        <Button
          onClick={() =>
            setSnackbar({
              message: 'Danger snackbar',
              variant: SnackbarVariant.Danger,
              icon: faExclamationTriangle,
            })
          }
        >
          Error snackbar
        </Button>
        <Button
          onClick={() =>
            setSnackbar({
              message: 'Success snackbar',
              variant: SnackbarVariant.Success,
              icon: faCheckCircle,
            })
          }
        >
          Success snackbar
        </Button>
      </Section>
      <Section>
        <Text kind={TextKind.TitleL}>Trigger errors</Text>
        <Button variant={ButtonVariant.Danger} onClick={triggerApolloError}>
          Apollo Error
        </Button>
        <Button
          variant={ButtonVariant.Danger}
          onClick={triggerApplicationError}
        >
          Application Error
        </Button>
      </Section>
    </Container>
  );
};

export default Playground;
