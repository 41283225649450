export enum SplitFlags {
  MOCK_FLAG = 'mock-flag', // for use in unit tests that require `SplitFlags` but that aren't coupled to a specific feature flag
  VIRTUAL_PERSONAL_TRAINING = 'virtual-personal-training',
  CH_SCHEDULING_V2_1_FEEDBACK = 'ch-scheduling-v2-1-feedback',
  CH_UNREAD_COUNT_SUBSCRIPTIONS = 'ch-unread-count-subscriptions',
  CH_REPORT_EXTERNAL_SESSIONS = 'ch-report-external-sessions',
  CH_CONTENT_LIBRARY = 'ch-content-library',
  NS_INTAKE_FORM = 'ch-nutrition-intake-form',
  METHODOLOGY_BOT_ENABLED = 'methodology-bot-enabled',
  CH_MULTI_COACH_CHAT = 'ch-multi-coach-chat',
}
