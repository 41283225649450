import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Snackbar as MuiSnackbar } from '@mui/material';
import { styled as MuiStyled } from '@mui/system';
import styled from 'styled-components';

import {
  AQUAMARINE,
  BLACK2,
  COOL_GRAY800,
  GRAY600,
  STATUS_ERROR,
} from '../../../theme';
import Text, { TextColor, TextKind, textStyleFrom } from '../Text';

const StyledSnackbar = MuiStyled(MuiSnackbar)`
  & .MuiPaper-root {
    background-color: ${BLACK2};
    min-width: unset;
    display:flex;
    flex-wrap: nowrap;
    gap: 40px;
  }

  & .MuiSnackbarContent-action {
    margin: 0;
    padding: 0;
  }
`;

const StyledMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: max-content;
`;

const SnackbarActionButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;

  &:hover {
    background: ${COOL_GRAY800};
  }

  &:active {
    background: ${GRAY600};
  }

  ${textStyleFrom(TextKind.ButtonM, TextColor.Contrast)};
`;

export enum SnackbarVariant {
  Success = 'success',
  Danger = 'danger',
}

const variantIconColorMap: Record<SnackbarVariant, string> = {
  [SnackbarVariant.Success]: AQUAMARINE,
  [SnackbarVariant.Danger]: STATUS_ERROR,
};

interface SnackbarProps {
  open: boolean;
  message: string;
  icon?: IconProp;
  variant?: SnackbarVariant;
  actionText?: string;
  onAction?: () => void;
  testId?: string;
}

export const Snackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  icon,
  variant = SnackbarVariant.Success,
  actionText,
  onAction,
  testId,
}) => (
  <StyledSnackbar
    open={open}
    data-testid={testId || 'snackbar'}
    data-variant={variant}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    message={
      <StyledMessage>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            color={variantIconColorMap[variant]}
            data-testid="snackbar-icon"
          />
        )}
        <Text kind={TextKind.BodyM} color={TextColor.Contrast}>
          {message}
        </Text>
      </StyledMessage>
    }
    action={
      actionText &&
      onAction && (
        <SnackbarActionButton onClick={onAction}>
          {actionText}
        </SnackbarActionButton>
      )
    }
  />
);
