import React from 'react';

import { Link, useMatch } from 'react-router-dom';
import styled from 'styled-components';

import useTracking from '../../../hooks/useTracking';
import { BLACK, WHITE } from '../../../theme';
import {
  EventCategories,
  EventLabels,
  EventNames,
} from '../../../utils/trackingEvents';
import Text, { TextColor, TextKind } from '../Text';

export const NavigationWrapper = styled.nav`
  display: flex;
  height: 100%;
`;

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  display: flex;
  width: 100px;
  height: 100%;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: ${({ $isActive }) => `3px solid ${$isActive ? WHITE : BLACK}`};
  padding-top: 3px;
`;

export const NavigationItem = ({
  children,
  to,
  testId,
  eventLabel,
}: {
  children: string;
  to: string;
  testId: string;
  eventLabel: EventLabels;
}) => {
  const isActive = !!useMatch(to);
  const { track } = useTracking();

  const onClick = () => {
    track(EventNames.NavTabClick, {
      event_category: EventCategories.Navigation,
      event_label: eventLabel,
    });
  };

  return (
    <StyledLink
      to={to}
      $isActive={isActive}
      aria-current={isActive}
      data-testid={testId}
      onClick={onClick}
    >
      <Text
        kind={TextKind.BodyM}
        color={TextColor.Contrast}
        component="span"
        bold={isActive}
      >
        {children}
      </Text>
    </StyledLink>
  );
};
