import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import canvasConfetti, {
  CreateTypes as ConfettiInstance,
} from 'canvas-confetti';
import noop from 'lodash/noop';

import { getConfettiConfig } from './config';

interface IConfettiContext {
  showConfetti: () => void;
  showingConfetti: boolean;
  setShowingConfetti: (showing: boolean) => void;
}

const Context = createContext<IConfettiContext>({
  showConfetti: noop,
  showingConfetti: false,
  setShowingConfetti: noop,
});

export const ConfettiContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [showingConfetti, setShowingConfetti] = useState<boolean>(false);

  const showConfetti = React.useCallback(() => {
    setShowingConfetti(true);
  }, []);

  const context = useMemo(
    () => ({
      showConfetti,
      showingConfetti,
      setShowingConfetti,
    }),
    [showConfetti, showingConfetti, setShowingConfetti],
  );

  useEffect(() => {
    if (!showingConfetti) {
      return;
    }

    // Wait 3 seconds to stop confetti
    setTimeout(() => {
      setShowingConfetti(false);
    }, 3000);
  }, [showingConfetti]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useConfettiContext = () => useContext(Context);

function getWindowSize() {
  return { width: document.body.clientWidth, height: window.innerHeight };
}

const Confetti: React.FC = () => {
  const { showingConfetti, setShowingConfetti } = useConfettiContext();

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const confetti = useRef<ConfettiInstance | null>(null);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    async function runConfetti() {
      if (showingConfetti && confetti.current) {
        const config = getConfettiConfig();

        const commonConfig = {
          spread: 70,
          particleCount: 250,
          startVelocity: windowSize.width * 0.07,
          ticks: 250,
          ...config,
        };

        // Right cannon
        confetti.current({
          ...commonConfig,
          origin: { y: 0.5, x: 1.1 },
          angle: 125,
        });

        // Left cannon
        confetti.current({
          ...commonConfig,
          origin: { y: 0.5, x: -0.1 },
          angle: 60,
        });

        setShowingConfetti(false);
      }
    }
    runConfetti();
  });
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    confetti.current = canvasConfetti.create(canvasRef.current, {
      resize: true,
      useWorker: false,
    });
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 100,
      }}
      width="100%"
      height="100%"
    />
  );
};

export default Confetti;
