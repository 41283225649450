import {
  EventCategories,
  EventLabels,
  ScreenNames,
} from '../../utils/trackingEvents';

import { SignInFlow } from './types';

export const getButtonText = (loading: boolean, user?: any) => {
  if (loading) {
    return 'Loading';
  }

  if (user) {
    return 'Sign In';
  }

  return 'Continue';
};

export const getMixpanelEventsBasedOnFlow = (flow: SignInFlow) => {
  switch (flow) {
    case SignInFlow.Email:
      return {
        event_category: EventCategories.SignIn,
        event_label: EventLabels.Continue,
        screen_name: ScreenNames.SignInEmail,
      };
    case SignInFlow.Password:
      return {
        event_category: EventCategories.SignIn,
        event_label: EventLabels.LogIn,
        screen_name: ScreenNames.SignInPassword,
      };
    default:
      return {};
  }
};
