import React from 'react';

import { UseFormRegisterReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';

import useTracking, { TrackingEventData } from '../../../hooks/useTracking';
import {
  BLACK,
  BORDER_GRAY,
  COOL_GRAY100,
  TEXT_DISABLED,
  WHITE,
} from '../../../theme';
import { EventNames } from '../../../utils/trackingEvents';
import Errors from '../ErrorMessage';
import Text, { TextColor, TextKind } from '../Text';

export enum InputSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const StyledInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const TextFieldCSS = css`
  box-sizing: border-box;
  width: 100%;

  border: 1px solid ${BORDER_GRAY};
  border-radius: 8px;
  font-family: reader;
  font-size: 14px;
  background-color: ${WHITE};
  &:focus {
    border-color: ${BLACK};
  }
  &:disabled {
    color: ${TEXT_DISABLED};
    background: ${COOL_GRAY100};
  }
`;

const StyledTextField = styled.input<{ $size?: InputSize }>`
  ${TextFieldCSS}

  ${({ $size }) => {
    switch ($size) {
      case InputSize.Large:
        return css`
          padding: 16px;
        `;
      case InputSize.Small:
        return css`
          height: 34px;
          border-radius: 4px;
          padding: 8px;
        `;
      case InputSize.Medium:
      default:
        return css`
          padding: 8px;
        `;
    }
  }}
`;

interface InputProps {
  label?: string;
  // default placeholder is the label
  placeholder?: string;
  // used for `useForm` register util
  formRegister?: UseFormRegisterReturn;
  onFocus?: () => void;
  autoFocus?: boolean;
  type?: string;
  size?: InputSize;
  optional?: boolean;
  id?: string;
  trackingData?: TrackingEventData;
  maxLength?: number;
}

const Input: React.FC<InputProps> = ({
  formRegister,
  label,
  type,
  placeholder,
  onFocus,
  autoFocus,
  size,
  optional,
  id,
  trackingData,
  maxLength,
}) => {
  const { track } = useTracking();
  const handleFocus = () => {
    if (trackingData && formRegister?.name) {
      track(EventNames.TextBoxActivated, trackingData);
    }
  };

  return (
    <StyledInputContainer>
      {label && (
        <StyledLabelContainer>
          <Text component="label" kind={TextKind.LabelL} htmlFor={id}>
            {label}
          </Text>
          {optional && (
            <Text
              component="label"
              kind={TextKind.BodyS}
              color={TextColor.Secondary}
            >
              (Optional)
            </Text>
          )}
        </StyledLabelContainer>
      )}
      <StyledTextField
        id={id}
        data-testid={id}
        autoFocus={autoFocus}
        type={type}
        placeholder={placeholder ?? label}
        onFocus={onFocus}
        $size={size}
        required={!optional}
        onFocusCapture={handleFocus}
        maxLength={maxLength}
        {...formRegister}
      />
      {formRegister?.name && (
        <Errors name={formRegister.name} errorTextKind={TextKind.BodyS} />
      )}
    </StyledInputContainer>
  );
};

export default Input;
